$base-font-size: 19px;
$images: '../../images';
$icons: '#{$images}/icons';


$d-gray: #1C1C1C;
$base: #434343;
$gray: #D8D8D8;
$blue: #00AFEB;
$red: #850017;
$green: #34620C;
$yellow: #FFD908;
$white: #FFFFFF;

//breakpoints
$breakpoints: (
        'hd': 1919px,
        'lg': 1364px,
        'nm': 1240px,
        'md': 991px,
        'sm': 768px,
        'xs': 480px,
        'xxs': 385px
)!default;