@import "preloader/src/loaders.scss";

.preloaderOverlay {
  position: absolute;
  background: rgba(0,0,0, 0);
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  .preloader {
    position: absolute !important;
    top: 50%;
    left: 50%;
    margin: (-$loader-size/2) 0 0 (-$loader-size/2);
    @include loader03($color: $red);
  }
}