.slider {
    position: relative;
    min-height: 180px;
    width: 100%;
    @include bdown(md) {
        min-height: 0px;
    }
    
}
.slick-slide {
    &:focus {
        outline: none;
    }
}
.slick-track {
    display: flex;
    align-items: center;
}
.slider-instance {
    &.init {
        opacity: 0;
        height: 80px;
    }
}
.slick-dots {
    display: block;
    li {
        display: inline-block;
        padding: 0 5px;
    }
    .slick-dot {
        width: 18px;
        height: 18px;
        background-color: transparent;
        border-radius: 50%;
        border: 3px solid $yellow;
        &:hover {
            background-color: $yellow;
        }
    }
    .slick-active {
        .slick-dot {
            background-color: $yellow;
        }
    }
}
//mobile menu opened effect
.nav-opened .main-slider {
    @include bdown(md){
        filter: brightness(20%);
    }
}

.main-slider {
    @include transition;
    &.slider-instance.init {
        height: 400px;
    }
    .slick-arrow {
        position: absolute;
        bottom: 3%;
        color: #fff;
        z-index: 100;
        font-family: $poppins;
        @include fluid-font(18px);
        @include transition;
        &:after {
            display: block;
            content: "";
            opacity: 0;
            width: 50%;
            margin-left: auto;
            margin-right: auto;
            background-color: $blue;
            height: 2px;
            @include transition;
        }
        &:hover {
            color: $green;
            &:after {
                opacity: 1;
            }
        }
    }
    .slick-prev {
        right: 22%;
        padding: 1px .33em 1px 0.25em;
    }
    .slick-next {
        right: calc(22% - 5em);
        padding: 1px .25em 1px 0.33em;
    }
    .slider-item {
        min-height: 30vh;
        max-height: 100vh;
        @extend %slider-item-abs;
    }
    .description {
        position: absolute;
        display: block;
        left: 50%;
        transform: translateX(-50%);
        top: 0;
        min-width: 33%;
        height: 100%;
        max-width: 1400px;
        width: 100%;
        z-index: 15;
        .inner {
            position: absolute;
            width: 35%;
            top: 160px;
            height: calc(100% - 160px);
            display: flex;
            flex-direction: column;
            justify-content: center;
            // height: 75%;
            // padding: 8% 3em 1em 3em;
        }
        .slider-title-block {
            @extend %title-block;
        }
        .slider-buttons-block {
            margin-top: .5em;
        }
    }
    .slick-dots {
        position: absolute;
        bottom: 5%;
        width: 100%;
        max-width: 1410px;
        left: 50%;
        transform: translateX(-50%);
        pointer-events: none;
        li {
            pointer-events: all;
        }
    }
    @include bup(md) {
        .description {
            .inner {
                width: 50%;
                min-width: 450px;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 0;
            }
        }
    }
    @include bup(sm) {
        .description {
            .slider-title-block {
                p {
                    max-width: 480px;
                }
            }
        }
    }
    @media(max-width: 1400px){
        .description {
            .inner {
                left: 5%;
                right: 5%;
            }
        }
        .slick-dots {
            padding-left: 5%;
        }
    }
    @include bdown(md) {
        .slider-item {
            min-height: 80vh;
        }
        .description {
            .inner {
                width: auto;
            }
        }
        &.no-min-height {
            .slider-item {
                min-height: 200px;
            }
        }
    }
    @include bdown(sm) {
        .description {
            .inner {
                padding: 1em 0;
            }
            .slider-buttons-block {
                margin-top: 0;
                .slider-button {
                    margin: .3em 0;
                    padding: .5em 1em;
                }
            }
        }
    }
    @include bdown(xs) {
        .description {
            .inner {
                // width: 100%;
                right: 0;
            }
            .slider-title-block {
                .title-2 {
                    padding-top: .15em;
                    padding-bottom: .15em;
                }
                p {
                    margin-bottom: 1em;
                }
            }
        }
    }
}
.middle-slider {
    &.slider-instance.init {
        height: 400px;
    }
    .slick-dots {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        bottom: 1rem;
    }
    &.alt-0 {
        .slider-item {
            @extend %slider-item-st;
        }
    }
    &.alt-1 {
        .slider-item {
            @extend %slider-item-st-alt-1;
        }
        .slick-dots {
            .slick-dot {
                border: 3px solid #3EB0CA;
                &:hover {
                    background-color: #3EB0CA;
                }
            }
            .slick-active {
                .slick-dot {
                    background-color: #3EB0CA;
                }
            }
        }
    }
    &.alt-2 {
        .slider-item {
            @extend %slider-item-st-alt-2;
        }
        .slick-dots {
            .slick-dot {
                border: 3px solid #3EB0CA;
                &:hover {
                    background-color: #3EB0CA;
                }
            }
            .slick-active {
                .slick-dot {
                    background-color: #3EB0CA;
                }
            }
        }
    }
}
.partners-slider {
    padding-left: 10%;
    padding-right: 10%;
    .slick-slide {
        // height: 70px;
        padding-left: 7px;
        padding-right: 7px;
        display: flex;
        align-items: center;
        img {
            margin-left: auto;
            margin-right: auto;
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: $gray;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 38);
            // @include fs-26;
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 3%;
    }
    .slick-next {
        right: 3%;
    }
    @include bdown(xs) {
        padding-left: 12%;
        padding-right: 12%;
        .slick-slide {
            img {
                max-height: 150px;
            }
        }
    }
    @include bdown(xxs) {
        .slick-slide {
            img {
                max-height: 120px;
            }
        }
    }
}
.retails-container {
    background-image: url(#{$images}/retail-slider/retails-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    color: #fff;
    h2 {
        color: $yellow;
        @include letter-spacing(20px);
    }
}
.retail-slider {
    padding-left: 10%;
    padding-right: 10%;
    .slick-slide {
        // height: 70px;
        padding-left: 7px;
        padding-right: 7px;
        display: flex;
        align-items: center;
        a {
            @extend %greenlink;
        }
        img {
            margin-left: auto;
            margin-right: auto;
            border-top: .63rem solid $yellow;
            display: block;
        }
        span {
            position: absolute;
            z-index: 10;
            left: 50%;
            transform: translateX(-50%);
            bottom: 10%;
            font-weight: 600;
            color: #fff;
            @include font-count(16, 46);
            @include letter-spacing(20);
            @include transition;
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: $gray;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 38);
            // @include fs-26;
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 3%;
    }
    .slick-next {
        right: 3%;
    }
    @include bdown(xs) {
        padding-left: 12%;
        padding-right: 12%;
    }
}
.testimonials-container {
    background-color: #F0F6F9;
    .container.fw {
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}
.testimonials-slider {
    
    .slick-track, .slick-list {
        display: flex !important;
        // justify-content: center;
    }
    .slick-track {
        justify-content: center;
    }
    .slick-slide {
        // display: flex;
        height: 100%;
    }
    .inner {
        padding: 4em 1.5em;
        position: relative;
        height: 100%;
    }
    .testimonial {
        padding: 8%;
        position: relative;
        background-color: $red;
        color: #fff;
        height: 100%;
        box-shadow: 0 2em 2.1em rgba(0, 0, 0, .18);
        border-radius: 5px;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-family: $poppins;
        &:before {
            content: "";
            display: block;
            position: absolute;
            top: -3em;
            left: .5em;
            width: 4em;
            height: 2.5em;
            background-image: url(#{$images}/icons/quotes.svg);
            background-repeat: no-repeat;
            background-size: contain;
        }
        p {
            margin-bottom: 0;
        }
        .ts {
            &-text {
                @include fluid-font(20px);
                margin-bottom: 1em;
            }
            &-author {
                font-weight: 500;
                @include fluid-font(18px);
                position: relative;
                padding-top: .5rem;
                &:before {
                    content: "";
                    top: 0;
                    width: 23px;
                    height: 3px;
                    left: 50%;
                    transform: translateX(-50%);
                    background-color: $yellow;
                    position: absolute;
                }
            }
            &-position {
                color: #5CA220;
                @include fluid-font(14px);
            }
        }
        img {
            margin-bottom: 2rem
        }
        p {
            @include fluid-font(19px);
        }
    }
    .slick-prev.icon,
    .slick-next.icon {
        position: absolute;
        transform: translateY(-50%);
        top: 50%;
        color: #D2D2D2;
        z-index: 100;
        color: $gray;
        &:before {
            @include transition;
            display: inline-block;
            text-align: center;
            @include font-count(20, 38);
        }
        &:hover {
            color: $blue;
        }
    }
    .slick-prev {
        left: 0;
    }
    .slick-next {
        right: 0;
    }
    .slick-dots {
        text-align: center;
    }
    @include bup(md) {
        padding-top: 16px;
        padding-bottom: 16px;
        padding-left: 5%;
        padding-right: 5%;
        max-width: 1400px;
        margin-left: auto;
        margin-right: auto;
    }
    @include bdown(md) {
        padding-top: 25px;
        padding-bottom: 0;
        .inner {
            padding-left: 5%;
            padding-right: 5%;
            margin-left: 5%;
            margin-right: 5%;
        }
    }
}