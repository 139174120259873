footer {
    color: #fff;
    position: relative;
    background-image: url(#{$images}/footer-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding-top: 42px;
    padding-bottom: 42px;
    &:after {
        content: "";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: rgba($color: #0A0A0A, $alpha: .82);
        z-index: 5;
    }
    h4 {
        text-align: left;
        color: $blue;
        @include fluid-font(21px);
        margin-top: 0;
        margin-bottom: .4em;
        @include bup(md) {
            line-height: 40px;
        }
    }
    a {
        color: inherit;
        &:hover, &.active {
            color: $blue;
        }
    }
    .container {
        position: relative;
        z-index: 10;
        max-width: 1152px;
        .footer-social-links {
            padding: 1em 0 1.5em;
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            li {
                flex: 0 1 auto;
                margin-right: 5px;
                position: relative;
                a {
                    color: #5CA220;
                    font-size: 3.15em;
                    @include transition;
                    &:hover {
                        &.icon-facebook-transparent {
                            color: #3B5998;
                        }
                        &.icon-twitter-transparent {
                            color: #49C9F2;
                        }
                        &.icon-instagram-transparent {
                            color: #3B749F;
                        }
                        &.icon-youtube-transparent {
                            color: #CE332D;
                        }
                        &.icon-linkedin-transparent {
                            color: #0775B7;
                        }
                        &.icon-ebay {
                            color: $blue;
                        }
                        &.icon-gun {
                            color: $red;
                        }
                    }
                }
            }
        }
        .footer-info {
            justify-content: space-between;
            h3 {
                color: $yellow;
                @include fluid-font(28px);
                font-weight: 500;
            }
            &-text {
                @include rlh(30px);
                span {
                    display: block;
                }
                margin-bottom: 1rem;
            }
            a {
                color: $yellow;
                @include fluid-font(28px);
                font-weight: 700;
                display: inline-flex;
                &:before {
                    content: "\e918";
                    font-family: $icomoon;
                    display: block;
                    padding-right: .5rem;
                }
            }
            // .col {
            //     flex: 0 0 29%;
            //     &:nth-of-type(2){
            //         flex: 0 0 31%;
            //     }
            // }
        }
        .footer-copyright {
            text-align: center;
            font-size: 12px;
            @include letter-spacing(200);
        }
        .footer-bottom-links {
            text-align: right;
            font-size: 80%;
            a {
                color: $white;
                &:hover {
                    color: $yellow;
                }
            }
        }
    }
    @include bup(md){
        .container {
            .footer-social-links {
                max-width: 55%;
            }
            .footer-info {
                .col {
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    margin-bottom: 1rem;
                }
            }
        }
    }
    @include bdown(md) {
        .container {
            .footer-social-links {
                max-width: 75%;
                li a {
                    font-size: 2em;
                }
            }
            .footer-bottom-links {
                text-align: center;
                margin-bottom: 1rem;
            }
        }
    }
}