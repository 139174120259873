.image-links {
    .inner {
        padding-left: 5px;
        padding-right: 5px;
    }
    .item {
        border: 5px solid #fff;
    }
    a {
        display: block;
        @extend %greenlink;
        img {
            width: 100%;
        }
    }
    span {
        position: absolute;
        z-index: 10;
        left: 50%;
        transform: translateX(-50%);
        bottom: 10%;
        width: 90%;
        text-align: center;
        font-weight: 600;
        color: #fff;
        @include font-count(16, 34);
        @include letter-spacing(20);
        @include rlh(28px);
        @include transition;
    }
}
.image-links-list {
    color: $green;
    li {
        display: inline-flex;
        font-weight: 600;
        @include font-count(15, 29);
        &:before {
            color: $blue;
            content: "\e931";
            font-family: $icomoon;
            font-size: 1.25em;
            margin-right: .5em;
        }
    }
    @include bup(md){
        li {
            padding-left: 2%;
            padding-right: 2%;
            padding-bottom: 1.5%;
        }
    }
    @include bdown(md){
        li {
            padding-left: 2%;
            padding-right: 2%;
            padding-bottom: 1.5%;
        }
    }
}
.img-bordered-container {
    position: relative;
    display: inline-block;
    padding-top: 1.42rem;
    padding-left: 1.21rem;
    &:before {
        content: "";
        display: block;
        position: absolute;
        width: 80%;
        height: 1.42rem;
        left: 0;
        top: 0;
        background-color: $blue;
    }
    &:after {
        content: "";
        display: block;
        position: absolute;
        width: 1.21rem;
        height: 50%;
        left: 0;
        top: 1.42rem;
        background-color: $blue;
    }
}
.faq {
    .acc-tab {
        background-color: rgba(#D8D8D8, $alpha: .2);
        margin-bottom: 1em;
        cursor: pointer;
        .acc-text {
            padding-left: 3em;
            padding-right: 4.84rem;
            padding-bottom: 1em;
            padding-top: 1em;
            margin-left: 3em;
            @include fluid-font(18px);
            @include rlh(29px);
            font-weight: 400;
            color: #262626;
            position: relative;
            &:before {
                content: "\e926";
                font-size: 2em;
                font-family: $icomoon;
                color: $blue;
                position: absolute;
                left: 0;
                top: 50%;
                transform: translateY(-50%);
            }
            .btn {
                display: inline-flex;
                align-items: center;
                &:after {
                    content: "\e91e";
                    margin-left: .5rem;
                    font-family: $icomoon;
                }
            }
        }
        .acc-title {
            color:$green;
            @include fluid-font(29px);
            @include transition;
            font-weight: 600;
            padding-left: 3rem;
            padding-right: 6rem;
            min-height: 4.84rem;
            display: flex;
            align-items: center;
            position: relative;
            &:after {
                @include transition;
                content: "+";
                display: flex;
                position: absolute;
                font-size: 62px;
                width: 4.84rem;
                height: 4.84rem;
                color: $green;
                right: 0;
                top: 0;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;
                // height: 100%;
                cursor: pointer;
                @include bdown(xs) {
                    font-size: 40px;
                    width: 50px;
                }
            }
            &:before {
                content: "\e925";
                font-size: 1em;
                font-family: $icomoon;
                color: $blue;
                position: absolute;
                left: 0.5em;
                top: 50%;
                transform: translateY(-50%);
            }
            &:hover:after {
                color: $base;
            }
            &.accordion-active {
                &:after {
                    color: $base;
                    content: "-";
                    max-height: 4.84rem;
                }
            }
        }
        
    }
    .faq-btn {
        display: inline-flex;
        @include font-count(18, 36);
        align-items: center;
        &:hover{
            filter: brightness(80%);
        }
        img {
            margin-right: .5em;
            height: 2.1em;
            width: auto;
        }
        span {
            background-color: $green;
            color: #fff;
            font-family: $montserrat;
            font-weight: 500;
            border-radius: 6px;
            max-width: 325px;
            @include rlh(33px);
            @include letter-spacing(20);
            display: inline-flex;
            justify-content: flex-start;
            text-align: left;
            align-items: center;
            padding: .5rem 1rem;
        }
    }
}
.back-link {
    color: #A2A0A0;
    &:before {
        color: $blue;
    }
    &:hover {
        color: $blue;
    }
}

section.sign-up {
    color: #fff;
    background-image: url(../../images/icons/envelope.svg);
    background-repeat: no-repeat;
    background-position: 90% 107%;
    background-size: auto;
    background-color: $green;
    .row {
        align-items: center;
        h3 {
            text-align: left;
            color: inherit;
            font-family: $montserrat;
            font-weight: 500;
            @include letter-spacing(20);
            @include rlh(33px);
            & + p {
                font-family: $poppins;
                @include fluid-font(14px);
                letter-spacing: .2em;
                @include rlh(15px);
                color: rgba($color: #fff, $alpha: .5);
            }
        }
    }
    form {
        display: flex;
        align-content: center;
        justify-content: center;
        margin-left: auto;
        margin-right: auto;
    }
    .input-container {
        position: relative;
        // min-width: 60%;
        border-radius: 5px;
        margin-right: .5em;
        background-color: #fff;
        i {
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            right: 1em;
            color: $blue;
            font-size: 1.3em;
        }
    }
    input {
        background: transparent;
        border: none;
        flex: 1 1 0;
        padding: 0 4em 0 1em;
        height: 100%;
        width: 100%;
        @include font-count(13, 18);
        font-family: $poppins;

    }
    .btn {
        background-color: transparent;
        border: none;
        @include transition;
        white-space: nowrap;
        background-color: #363636;
        &:hover {
            background-color: $red;
        }
    }
    @include bup(lg){
        .input-container {
            min-width: 70%;
        }
    }
    @include bup(md) {
        .col:nth-child(1){
            padding-left: 7%;
        }
        .input-container {
            min-width: 60%;
            margin-right: 2em;
        }
    }
    @include bdown(md) {
        .row h3, .row h3 + p {
            text-align: center;
        }
    }
}

section.our-news {
    background-color: rgba(#000000, $alpha: .88);
    h2 {
        margin-bottom: 2.3rem;
        color: $yellow;
    }
    .our-news-card {
        display: block !important;
        font-family: $poppins;
        padding-bottom: 2rem;
        position: relative;
        height: 100%;
        .our-news-card-description {
            @include fluid-font(23px);
            @include transition;
            @include rlh(30px);
            // line-height: 30px;
            font-weight: 600;
            color: #BEE4ED;
        }
        .our-news-card-date {
            color: #737F9A;
            @include transition;
            @include fluid-font(16px);
            padding: 0 0 13px;
            position: absolute;
            bottom: 0;
            left: 0;
        }
        .our-news-card-media {
            padding-bottom: 34px;
            .our-news-card-media-wrapper {
                position: relative;
                height: 0;
                padding-bottom: (9 * 100% / 16);
                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
                img {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: auto;
                    height: 100%;
                }
            }
        }
        &:hover {
            .our-news-card-date,
            .our-news-card-description {
                color: $blue;
            }
        }
    }
}
.icon-links {
    .col {
        display: flex;
        padding: 1rem;
        justify-content: flex-start;
        align-items: center;
        span {
            @include fluid-font(22px);
            @include rlh(29px);
            font-weight: 500;
            display: block;
            margin-left: 1rem;
        }
    }
}



section.wwd {
    h4 {
        color: $blue;
        border-top: 10px solid $blue;
        font-family: $montserrat;
        font-weight: 400;
        padding-top: 1rem;
    }
}

section.fullwidth-banner {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: right center;
    padding-top: 0;
    padding-bottom: 0;
    &.position-right {
        .container {
            text-align: right;
        }
    }
    .banner-info {
        text-align: left;
        display: inline-block;
        padding-left: 2em;
        padding-right: 2em;
        color: #fff;
        .title-1 {
            color: $blue;
            @include fluid-font(72px);
            font-family: $poppins;
            padding-bottom: .2em;
            font-weight: 700;
        }
        .title-2 {
            @include fluid-font(26px);
            color: #D8D8D8;
            padding-bottom: .2em;
        }
        .btn {
            background-color: $blue;
        }
    }
    @include bup(md){
        .banner-info {
            max-width: 660px;
            padding-top: 7em;
            padding-bottom: 5em;
        }
    }
    @include bdown(md){
        .banner-info {
            max-width: 50%;
            padding-top: 30px;
            padding-bottom: 30px;
        }
    }
    @include bdown(sm){
        .banner-info {
            max-width: none !important;
        }
    }
}


section.our-info {
    h3 {
        @include fluid-font(36px);
        font-family: $poppins;
    }
    h3 + div {
        @include fluid-font(36px);
        font-family: $poppins;
        a {
            display: block;
            margin-top: 1em;
            &:hover {
                color: $blue;
            }
        }
    }
}

section.tcc {
    .row {
        &.img-left {
            .tc-img {
                order: 1;
            }
            .tc-text {
                order: 2;
                background-position: 90% center;
            }
        }
        &.img-right {
            .tc-img {
                order: 2;
            }
            .tc-text {
                order: 1;
                background-position: 10% center;
            }
        }
    }
    .col {
        padding-left: 0;
        padding-right: 0;
        flex: 1 1 50%;
    }
    .tc-img {
        position: relative;
        img {
            width: 100%;
            display: block;
        }
    }
    .tc-text {
        background-color: #1C1C1C;
        background-image: url(../../images/custom/tcc-text-bg.jpg);
        background-size: cover;
        background-repeat: no-repeat;
        color: #fff;
        padding: 4em 9% 3em;
        @include font-count(14, 18);
        margin-bottom: 0;
        h2 {
            @include font-count(52, 66);
            text-align: left;
            margin-bottom: .1em;
        }
        .subtitle {
            color: #fff;
            text-align: left;
            font-family: $montserrat;
            @include font-count(32, 40);
            font-weight: 600;
        }
        h5 {
            margin-bottom: 1.5em;
            font-family: $poppins;
            font-weight: 500;
            line-height: 28px;
            text-align: left;
            font-size: inerit;
        }
        p {
            line-height: 28px;
            &:last-of-type {
                margin-bottom: 2em;
            }
        }
        .icon {
            margin-left: 1em;
        }
        & > a {
            letter-spacing: .3em;
            font-family: $poppins;
            font-weight: 600;
            font-size: 14px;
            color: #fff;
            &:hover {
                filter: none;
            }
        }
    }
    img.img-cover {
        position: absolute;
        width: 100%;
        height: 100%;
        @include bdown(md){
            position: static;
        }
    }
}


.events-filter-title {
    color: #fff;
    @include fluid-font(55px);
    letter-spacing: normal;
    text-align: center;
    @include bup(md) {
        margin-bottom: -.5em;
    }
}
.gallery,
.upcoming-events {
    .gallery-filter,
    .upcoming-events-filter {
        display: flex;
        justify-content: space-around;
        padding-bottom: 10px;
        margin-top: 3em;
        a {
            text-transform: uppercase;
            color: $blue;
            background-color: $blue;
            font-family: $poppins;
            font-weight: 600;
            padding: .5em 1.5em;
            &.active {
                background-color: $d-gray;
                position: relative;
            }
        }
    }
    .gallery-filter {
        a {
            color: $blue;
            background-color: $blue;
        }
    }
    .upcoming-events-filter {
        a {
            color: #A2A0A0;
            background-color: transparent;
        }
    }
    @include bdown(sm){
        .gallery-filter,
        .upcoming-events-filter {
            display: block;
            a {
                display: block;
                margin-bottom: .3em;
            }
        }
    }
    @include bup(sm){
        .gallery-filter,
        .upcoming-events-filter {
            a.active {
                color: $blue;
                &:after {
                    content: "";
                    position: absolute;
                    bottom: -11px;
                    left: 50%;
                    transform: translateX(-50%);
                    width: 0;
                    height: 0;
                    border-style: solid;
                    border-width: 15px 15px 0 15px;
                    border-color: #1c1c1c transparent transparent transparent
                }
            }
        }
    }
}
.internal-gallery {
    .gallery-item {
        // position: relative;
        img {
            padding-bottom: 3.5rem;
            object-fit: cover;
            // padding-bottom: 3.6rem;

        }
    }
    .inner {
        height: 3.5rem;
        position: absolute;
        z-index: 100;
        bottom: 0;
        left: 0;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #000;
        color: #fff;
    }
}
.pswp__caption__center {
    text-align: center !important;
}

.support-block {
    background-color: $blue;
    margin-bottom: .5em;
    &--side-info {
        h4 {
            max-width: 320px;
            border-top: 10px solid $blue;
            font-weight: normal;
            padding-top: .5em;
        }
    }
    &--title {
        font-weight: normal;
    }
    &--text {
        margin-bottom: 1em;
    }
}
section.our-partners {
    .see-list {
        position: absolute;
        top: 16%;
        right: 10%;
        display: inline-flex;
        align-items: center;
        span {
            &:first-child {
                font-family: $poppins;
                font-size: 14px;
                color: #A2A0A0;
                font-weight: 600;
                @include letter-spacing(200);
                
            }
            &:last-child {
                color: $blue;
                padding-left: .5em;
            }
        }
        &:hover {
            span:first-child {
                color: $blue;
            }
        }
        @media (max-width: 1200px) {
            position: static;
        }
    }
}
section.our-partners-list {
    .partners-list {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: space-around;
        margin-top: 3em;
        margin-bottom: 3em;
        li {
            flex: 0 1 23%;
            padding: 1em;
            margin: 1%;
            box-shadow: 0 2px 6px rgba(0, 0, 0, .16);
            height: 130px;
            position: relative;
            img {
                position: absolute;
                left: 10%;
                top: 10%;
                width: 80%;
                height: 80%;
                object-fit: contain;
                object-position: center;
            }
        }
        @include bdown(md){
            li {
                flex: 0 0 48%;
                height: 100px;
            }
        }
    }
}
.membership {
    &--top {
        .col:first-child {
            border-bottom: 1px solid #5F5F5F;
        }
    }
    &--awards {
        .award-block{
            background-color: $blue;
            padding: 1.5rem;
            margin-bottom: 1em;
            &--top {
                display: flex;
                justify-content: space-between;
                align-items: center;
                border-bottom: 5px solid $blue;
                padding-bottom: .3em;
                h3 {
                    font-weight: normal;
                    font-family: $montserrat;
                    margin-bottom: 0;
                }
                @include bdown(md){
                    flex-direction: column;
                    h3 {
                        margin-bottom: .5em;
                    }
                }
                .soc-share-container {
                    @extend %share-style1;
                }
            }
            &--bottom {
                display: flex;
                flex-wrap: wrap;
                align-items: center;
                justify-content: space-around;
                padding-top: 1em;
            }
            &--winner {
                flex: 0 1 30%;
                padding-bottom: 1em;
                div:first-child {
                    font-style: italic;
                    color: $blue;
                    @include fluid-font(17px);
                }
                div:last-child {
                    @include fluid-font(20px);
                    color: #5f5f5f;
                }
                @include bdown(sm) {
                    flex: 1 1 100%;
                }
            }
        }
    }
}

section.cf {
    &-send-message {
        h4 {
            font-family: $montserrat;
            color: $blue;
            padding-top: 1em;
            border-top: 10px solid $blue;
            display: inline-block;
        }
        .c-info {
            color: #fff;
        }
        .btn-container {
            text-align: right;
            .btn {
                display: inline-flex;
                align-items: center;
                border: none;
            }
        }
    }
}
.get-in-touch {
    background-color: #020201;
    .wrap-3 {
        justify-content: space-between;
    }
    &--item {
        color: #fff;
        max-width: 300px;
        @include bdown(md){
            margin-bottom: 3rem;
            margin-left: auto;
            margin-right: auto;
        }
        span {
            display: block;
            margin-bottom: .5rem;
            @include rlh(30px);
        }
        .phone {
            color: $yellow;
            display: inline-flex;
            align-items: center;
            border-top: 1px solid #707070;
            border-bottom: 1px solid #707070;
            margin-top: 1rem;
            margin-bottom: 1rem;
            padding-top: .5rem;
            padding-bottom: .5rem;
            @include fluid-font(30px);
            font-weight: 700;
            &:hover {
                color: $red;
            }
            &:before{
                padding-right: .5rem;
            }
        }
        .directions {
            display: inline-flex;
            color: #FF2E08;
            @include fluid-font(23px);
            font-weight: 500;
            &:hover {
                color: $red;
            }
            &:before {
                content: "\f279";
                font-family: $icomoon;
                font-size: 2em;
                padding-right: .5rem;
            }
        }
    }
}

.media-wrapper,
.featured-media-container.video {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
    iframe {
        position: absolute;
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }
}
.img-cover {
    object-fit: cover;
    font-family: 'object-fit: cover;';
}
.soc-share-container {
    padding-top: .5em;
    padding-right: 2%;
    @include bdown(md) {
        max-width: 190px;
        margin-left: auto;
        margin-right: auto;
        padding-top: 25px;
        float: none;
        padding-right: 0;
    }
    .ssc-title {
        @include fluid-font(19px);
        font-family: $poppins;
        color: $blue;
        margin-bottom: 5px;
    }
    ul {
        display: flex;
        justify-content: space-between;
        li {
            a {
                color: $blue;
                @include transition;
                @include fluid-font(20px);
                &:hover {
                    color: $blue;
                }
            }
        }
    }
}

