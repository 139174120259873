section.cf {
    position: relative;
    background-position: right 2rem;
    background-repeat: no-repeat;
    &--right {
        background-position: left 2rem;
        form {
            margin-left: auto;
        }
    }
    &:after {
        content: "";
        background-image: linear-gradient(rgba($color: #F6F7F5, $alpha: 1 ), rgba($color: #EDEFEC, $alpha: 1 ), rgba($color: #DFE1DE, $alpha: 0 ));
        position: absolute;
        left: 0;
        top: 0;
        height: 30%;
        width: 100%;
    }
    &.clear {
        background-position: right top;
        &:after {
            content: none;
        }
    }
    form {
        position: relative;
        z-index: 5;
    }
}

form {
    input, select, textarea {
        &.has-danger {
            border: 1px solid #f00 !important;
        }
    }
    textarea {
        font-family: inherit;
    }
    #thankyou-url {
        display: none;
    }
    .cap-img {
        display: flex;
        flex-grow: 2;
        justify-content: flex-end;
        align-items: center;
        span {
            color: #fff;
            @include fluid-font(20px);
        }
        @include bdown(md){
            justify-content: flex-start;
        }
    }
}

form.hsf {
    @extend %letter-form;
    
    background-color: rgba($color: $blue, $alpha: .57);
    border: .5625rem solid #fff;
    color: #4a4a4a;
    text-align: center;
    ::placeholder {
        color: #4a4a4a;
    }
    input:-webkit-autofill,
    input:-webkit-autofill:hover, 
    input:-webkit-autofill:focus, 
    input:-webkit-autofill:active  {
        transition: background-color 5000s ease-in-out 0s;
        // -webkit-box-shadow: 0 0 0 30px white inset !important;
    }
    // input::-internal-autofill-selected {
    //     background-color: transparent !important;
    // }


    input, select, textarea {
        color: #4a4a4a;
    }

    .col {
        margin-bottom: 1rem;
    }

    .btn {
        border-radius: 0;
        border: none;
        @include fluid-font(18px);
        font-weight: 400;
    }
    @include bup(md) {
        padding: 2rem;
        max-width: 55%;
    }
    @include bdown(md) {
        padding: 1rem;
    }
}
form#login-form{
    @extend %letter-form;
    .row {
        margin-bottom: 1rem;
    }
    .col {
        align-items: center;
        &.vcenter {
            display: inline-flex;
            margin-bottom: 5px;
        }
    }
    label {
        font-family: $montserrat;
        @include fluid-font(18px);
        font-weight: 700;
        @include letter-spacing(100);
    }
    input, select, textarea {
        background-color: #fff;
        padding: 0 1rem;
        font-weight: 700;
        @include fluid-font(18px);
        border-color: transparent;
        font-family: $montserrat;
    }
    input {
        line-height: 45px;
    }
    .btn {
        background-color: $blue;
        &:hover {
            background-color: $green;
        }
    }

}
form.default-form {
    @extend %default-form;
    background-color: $green;
    position: relative;
    h2 {
        @include fluid-font(45px);
    }
    h3 {
        @include fluid-font(27px);
    }
}
.pencil {
    .col {
        position: relative;
        &:after {
            position: absolute;
            top: 1.25rem;
            transform: translateY(-50%);
            content: "\f040";
            right: 2rem;
            font-family: $icomoon;
            color: #5C6983;
        }
    }
}
.banner-form {
    background-image: url(../../images/custom/sell-stuff-bg.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    form {
        background-color: $green;
        padding: 2rem;
        @include bup(md){
            max-width: 70%;
            margin-left: auto;
        }
    }
}
form.loan-calculator {
    min-height: 101px;
    @extend %default-form;
    color: #000;
    input, select, textarea {
        color: #000;
        padding: .73rem;
    }
    .loan-result {
        font-size: 120%;
        .loan-calc-result {
            font-weight: 700;
        }
    }
}

label.checkbox-container {
    display: flex;
    cursor: pointer;
    a {
        &:hover {
            color: $white;
        }
    }
    span {
        font-size: 80%;
        flex-basis: 85%;
        text-align: left;
    }
    input {
        width: 2rem;
        height: 2rem;
        margin-right: 1rem;
    }
}
