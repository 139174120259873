header {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    z-index: 1000;
    transition: background-color .4s;
    overflow: hidden;
    .container {
        max-width: 1440px;
        position: relative;
    }
    .header {
        &--container {
            display: flex;
            justify-content: space-between;
            margin-left: auto;
            margin-right: auto;
            max-width: 1580px;
            @include bdown(md){
                flex-direction: column;
            }
        }
        &--left {
            .logo {
                display: block;
                padding-top: .5em;
                position: relative;
                z-index: 10;
                // max-width: 82%;
                // @include bcw(82%);
            }
            @include bdown(md){
                max-width: 60%;
                margin-left: auto;
                margin-right: auto;
                .logo {

                }
            }
            @include bup(md){
                .logo {
                    width: 52%;
                    min-width: 378px;
                }
            }
        }
        &--right {
            display: flex;
            flex-direction: column;
            justify-content: space-between; 
            flex: 1 1 auto;
        }
        &--top {
            padding-top: 8px;
            padding-bottom: 8px;
            position: relative;
            & > .container {
                @include bdown(md){
                    display: flex;
                    justify-content: space-between;
                }
            }
            
            .btn {
                padding: 1em 2em;
                @include bup(md){
                    margin-left: 2rem;
                }
            }
            &-info-container {
                display: flex;
                justify-content: flex-end;
                align-items: flex-start;
            }
            &-info {
                display: inline-block;
                padding: 0 2rem;
                &:last-of-type {
                    border-left: 1px solid #707070;
                }
                h4 {
                    color: $yellow;
                    text-transform: uppercase;
                    @include fluid-font(16px);
                    font-weight: 400;
                }
                p {
                    color: #fff;
                    @include fluid-font(16px);
                    margin-bottom: 0;
                    @include rlh(22px);
                }
                a {
                    @include fluid-font(16px);
                    color: #fff;
                }
                @include bdown(md){
                    display: none;
                }
            }
        }
        &--bottom {
            position: relative;
            &:before {
                position: absolute;
                content: "";
                display: block;
                left: -9000px;
                right: -9000px;
                top: 0;
                bottom: 0;
                background-color: rgba($color: #000000, $alpha: .31);
                border-top: 3px solid $red;
                border-bottom: 3px solid $red;
            }
            .nav-container {
                display: flex;
                flex: 1 0 auto;
            }
            nav {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                a {
                    flex-grow: 1;
                    position: relative;
                    text-transform: uppercase;
                    color: #fff;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    flex: 0 0 auto;
                    @include fluid-font(16px);
                    @include transition;
                    @include letter-spacing(100);
                    line-height: 26px;
                    background-color: transparent;
                    padding: .7em 0;
                    &.nav-item-active {
                        color: $yellow;
                    }
                    &:hover {
                        color: $yellow;
                    }
                }
            }
            @include bup(md){
                .nav-container {
                    position: relative;
                    left: -70px;
                }
                nav {
                    a {
                        margin: 0 1em 0 0;
                    }
                }
            }
            @include bbetween(md, nm){
                nav {
                    a {
                        font-size: 11px;
                    }
                }
            }
            @include bdown(md) {
                .nav-container {
                    display: none;
                }
                nav {
                    flex-direction: column;
                    padding-left: 0;
                    padding-right: 0;
                    width: 100%;
                    // height: 100vh;
                    height: auto;
                    background-image: linear-gradient(to right, rgba(0, 140, 153, .95), rgba(62, 76, 202, .95));
                    a {
                        display: block;
                        font-size: 16px;
                        padding: .5em 5%;
                        border-bottom: 1px solid rgba(255, 255, 255, .15);
                        &.nav-item-active, &:hover {
                            background-color: $blue;
                            color: #D7D7D7;
                            &:after {
                                content: none;
                            }
                        }
                        &:last-of-type {
                            margin-left: 0;
                        }
                        &:before {
                            content: none;
                        }
                        &:after {
                            content: none;
                        }
                    }
                }
            }
        }

    }
    span.nav-toggle {
        text-align: center;
        font-family: 'icomoon';
        font-size: 2em;
        min-width: 25px;
        display: block;
        transition: transform .3s ease;
        transform-origin: 50% 50%;
        color: $blue;
        &:before {
            content: "\e91f";
        }
        &.close {
            transform: rotate(-180deg);
        }
        &.close:before {
            content: "\e91e";
        }
    }
}
.main-slider-loading {
    header {
        background-color: rgba(0, 0, 0, .8);
    }
}