.hello-lb {
    display: none;
    &.featherlight-inner {
        display: block;
    }
    form label {
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        input {
            width: 1.5rem;
            height: 1.5rem;
            margin-right: 0.75rem;
        }
    }
} 
.hello-lightbox {
    display: none;
    text-align: center;
    .featherlight-close-icon {
        display: none !important;
    }

    .featherlight-content {
        background-color: $white;
        border: $red 2px solid;
        border-radius: 2rem;
        overflow: initial;
        @include bdown(md){
            max-width: 90%;
        }
        @include bup(md){
            max-width: 800px;
        }

        .featherlight-close-icon {
            font-size: 2rem;
            color: get-color('white');
            top: 25px;
            right: 25px;
            width: 40px;
            line-height: initial;
            @media screen and (max-width: 768px) {
                top: 15px;
            }
        }
    }

    .inner {
        padding-top: 1rem;
    }
}