
@mixin bdown($bp, $bps:$breakpoints) {
    @media screen and (max-width: map-get($bps, $bp) - 1px) {
        @content;
    }
}
@mixin bup($bp, $bps:$breakpoints) {
    @media screen and (min-width: map-get($bps, $bp)) {
        @content;
    }
}

@mixin bonly($bp, $bps:$breakpoints) {
    $lst-bps: map-keys($bps);
    $idx: index($lst-bps, $bp);
    @if( ($idx > 1) and ($idx < length($lst-bps)) ){
        @media screen and (min-width: map-get($bps, nth($lst-bps, ($idx + 1))) + 1px) and (max-width: map-get($bps, $bp)) {
            @content;
        }
    }
    @else {
        @if ($idx == 1) {
            @media screen and (min-width: map-get($bps, $bp) + 1px) {
                @content;
            }
        }
        @else {
            @media screen and (max-width: map-get($bps, $bp)) {
                @content;
            }
        }
    }
}

@mixin bbetween($bp1, $bp2, $bps:$breakpoints) {
    $lst-bps: map-keys($bps);
    $idx: index($lst-bps, $bp1);
    $start: map-get($bps, $bp1) + 1px;
    $end: map-get($bps, $bp2);
    @if ($end < $start) {
        $start: $start + $end;
        $end: $start - $end;
        $start: $start - $end;
    }
    @media screen and (min-width: $start) and (max-width: $end) {
        @content;
    }
}
@mixin clear-link {
    &:link,
    &:visited ,
    &:hover ,
    &:active {
        text-decoration: none;
    }
}
@mixin transition {
    transition: all .3s ease-in-out;
}
@mixin resp-media($name, $color, $color-hover) {
    .div-#{$name} {
        a {
            color: #{$color};
            &:focus, &:hover {
                color: #{$color-hover};
            }
        }
    }
}
@mixin animations($value) {
    -webkit-animation: $value;
    -moz-animation: $value;
    -o-animation: $value;
    animation: $value;
}
@mixin transform($transforms) {
    -moz-transform: $transforms;
    -o-transform: $transforms;
    -ms-transform: $transforms;
    -webkit-transform: $transforms;
    transform: $transforms;
}
@mixin block-center {
    margin-left: auto;
    margin-right: auto;
}
@mixin bcw ($value) {
    margin-left: auto;
    margin-right: auto;
    max-width: $value;
}

@mixin font-count($mins, $maxs){
    $minspx: #{$mins + px};
    $dif: #{($maxs - $mins) * .05vw};
    font-size: calc(#{$minspx} + #{$dif});
    @include bup(hd) {
        font-size: #{$maxs + px};
    }
    @include bdown(xs) {
        font-size: #{$mins + px};
    }
}
//@mixin fluid-font($maxs){
//
//    $mins: $maxs/1.4;
//    $dif: #{($maxs - $mins) * .06vw};
//    $minspx: #{$mins + px};
//    font-size: calc(#{$minspx} + #{$dif});
//    @include bup(hd) {
//        font-size: #{$maxs + px};
//    }
//    @include bdown(xs) {
//        font-size: #{$mins + px};
//    }
//}
@function strip-unit($value) {
    @return $value / ($value * 0 + 1);
}

@mixin fluid-font($max-font-size) {
    $min-font-size: $max-font-size/1.4;
    $min-vw: 480px;
    $max-vw: 1920px;
    $u1: unit($min-vw);
    $u2: unit($max-vw);
    $u3: unit($min-font-size);
    $u4: unit($max-font-size);

    font-size: $min-font-size;
    @media screen and (min-width: $min-vw) {
        font-size: calc(#{$min-font-size} + #{strip-unit($max-font-size - $min-font-size)} * ((100vw - #{$min-vw}) / #{strip-unit($max-vw - $min-vw)}));
    }
    @media screen and (min-width: $max-vw) {
        font-size: $max-font-size;
    }
}

@mixin letter-spacing($points) {
    $empoints: #{$points / 1000};
    letter-spacing: #{$empoints + em};
}

@mixin rlh($pixels){
    @include bup(md){
        line-height: $pixels;
    }
}
@mixin rw ($value) {
    @include bup(md){
        max-width: $value;
    }
}

@mixin btn-detail($color, $hover-color, $border-width, $fsmin, $fsmax) {
    color: #{$color};
    border: #{$border-width}px solid $color;
    @include font-count($fsmin, $fsmax);
    @include transition;
    &:hover {
        color: #{$hover-color};
        border: #{$border-width}px solid $hover-color;
    }
}
@mixin row() {
	display: flex;
	flex-wrap: wrap;
}

@mixin column($width, $grow:0, $shr: 1, $bs: $width) {
	width: $width;
	flex: #{$grow} #{$shr} #{$bs};
}
@for $i from 1 through 100 {
    $flex-basis: $i;
    .basis-#{$i} { 
        flex-basis: percentage($flex-basis)/100 !important;
    }
}
@for $i from 1 through 5 {
    .mt-#{$i}{
        margin-top: #{$i}rem;
    }
    .mb-#{$i}{
        margin-bottom: #{$i}rem;
    }
    .ml-#{$i}{
        margin-left: #{$i}rem;
    }
    .mr-#{$i}{
        margin-right: #{$i}rem;
    }
    .ms-#{$i}{
        margin-left: #{$i}rem;
        margin-right: #{$i}rem;
    }
    .mv-#{$i}{
        margin-top: #{$i}rem;
        margin-bottom: #{$i}rem;
    }
    .wrap-#{$i}{
        flex-wrap: wrap;
        display: flex;
        .col {
            flex: 0 0 calc(100% / #{$i});
        }
        & > * {
            flex: 0 0 calc(100% / #{$i});
        }
    }
    @include bdown(md){
        .wrap-#{$i}{
            .col {
                flex: 0 0 49%;
            }
            & > * {
                flex: 0 0 49%;
            }
        }
    }
    @include bdown(xs){
        .wrap-#{$i}{
            .col {
                flex: 0 0 100%;
            }
            & > * {
                flex: 0 0 100%;
            }
        }
    }
    @include bup(md){
        .rml-#{$i}{
            margin-left: #{$i}rem;
        }
        .rmr-#{$i}{
            margin-right: #{$i}rem;
        }
        .rpl-#{$i}{
            padding-left: #{$i}rem;
        }
        .rpr-#{$i}{
            padding-right: #{$i}rem;
        }
    }
    .pt-#{$i}{
        padding-top: #{$i}rem;
    }
    .pb-#{$i}{
        padding-bottom: #{$i}rem;
    }
    .ps-#{$i}{
        padding-left: #{$i}rem;
        padding-right: #{$i}rem;
    }
    .pv-#{$i}{
        padding-top: #{$i}rem;
        padding-bottom: #{$i}rem;
    }
    .p-#{$i}{
        padding: #{$i}rem;
    }
    .m-#{$i}{
        margin: #{$i}rem;
    }
}