@import "typography";
html {
    font-size: 19px;
    font-family: $poppins;
    @include bdown(md){
        font-size: 13px;
    }
}
a[href],
button {
    &:not(.disabled):not(:disabled) {
        cursor: pointer;
    }
    &.disabled,
    &:disabled {
        cursor: default;
        pointer-events: none;
    }
    transition: all .3s ease-in-out;
}
body {
    line-height: 1.25;
    @include fluid-font(19px);
}
.gray {
    color: $gray;
}
.blue {
    color: $blue;
}
.red {
    color: $red;
}
.green {
    color: $green;
}
.yellow {
    color: $yellow;
}
.white {
    color: #fff;
}
.blue-bg {
    background-color: #E7F7FD;
}
.green-bg {
    background-color: $green;
}
.dark-blue-bg {
    background-color: #1D235B;
}
.no-bg {
    background-image: none !important;
}
.underline {
    text-decoration: underline;
}
.all-caps {
    text-transform: uppercase;
}
.smaller {
    font-size: .7em;
}
.block-center {
    margin-left: auto;
    margin-right: auto;
}
.thankyou-url {
    display: none !important;
}
h2, .h2-style {
    //@include font-count(28, 57);
    @include fluid-font(48px);
    line-height: 1;
    position: relative;
    color: $blue;
}
h3 {
    @include fluid-font(36px);
    @include letter-spacing(100);
}
h1, h2, h3, h4, h5, h6 {
    margin-bottom: .4em;
    font-family: $poppins;
    font-weight: 600;
    @include letter-spacing(-25);
    color: $red;
}
p {
    margin-bottom: 1em;
}
h4 {
    @include fluid-font(26px);
}
h5 {
    @include fluid-font(22px);
}
h6 {
    @include fluid-font(19px);
}
.text-left {
    text-align: left;
}
.text-center {
    text-align: center;
}
.text-right {
    text-align: right;
}
.font-size {
    &-xs {
        font-size: 60%;
    }

    &-sm {
        font-size: 80%;
    }
    &-n {
        font-size: 100%;
    }

    &-l {
        font-size: 125%;
    }

    &-xl {
        font-size: 150%;
    }

    &-xxl {
        font-size: 200%;
    }

    &-xxxl {
        font-size: 300%;
    }

    &-xxxxl {
        font-size: 400%;
    }

    &-xxxxxl {
        font-size: 500%;
    }
}
.line-height{
    &-normal {
        line-height: normal;
    }
}
.visibility{
    &-hidden {
        visibility: hidden;
    }
}
.text {
    &-red {
        color: $red;
    }
}
@include bup(md){
    .r-text-left {
        text-align: left;
    }
    .r-text-center {
        text-align: center;
    }
    .r-text-right {
        text-align: right;
    }
}
.flex {
    &--am {
        align-items: center;
    }
}
p {
    line-height: 30px;
    &.subtitle {
        margin-bottom: .5em;
        text-align: center;
        @include letter-spacing(200);
        @include fluid-font(26px);
        color: $blue;
    }
    @include bdown(md) {
        line-height: 1.5;
    }
    // &.intro {
    //     font-family: $montserrat;
    //     margin-bottom: .5em;
    //     @include font-count(17, 22);
    //     @include letter-spacing(30);
    // }
}
.pretitle {
    margin-bottom: .3rem;
    font-family: $poppins;
    @include fluid-font(24px);
    @include letter-spacing(-25);
    color: #868686;
}

ul.blue-dots {
    li {
        list-style-type: none;
        position: relative;
        padding-left: 1em;
        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 6px;
            height: 6px;
            border-radius: 50%;
            background-color: $blue;
            display: inline-block;
        }
    }
}

a[name^="eztoc"] {
    display: none !important;
}
// .text-center {
//     ul {
//         text-align: left;
//     }
// }
.btn {
    text-transform: uppercase;
    color: #fff;
    border-radius: 5px;
    background-color: $red;
    @include fluid-font(16px);
    padding: 1em 1.6em;
    @include transition;
    font-family: $poppins;
    &:hover {
        background-color: $green;
    }
    &--green {
        background-color: $green;
        &:hover {
            background-color: $red;
        }
    }
    &--small {
        font-size: 13px;
        padding: .6em 1.6em;
    }
    &--large {
        padding: 1em 3em;
    }
    &--huge {
        @include fluid-font(33px);
        text-transform: uppercase;
        padding: .8em 2em;
        &-alt {
            @include fluid-font(26px);
            background-color: #fff;
            color: $base;
            border: 1px solid $blue;
        }
    }
    &--block {
        display: block;
        text-align: center;
    }
    &.arrowed {
        padding: 1em 2.8em 1em 1.6em;
        position: relative;
        &:after {
            position: absolute;
            content: "\e916";
            display: block;
            right: 1em;
            top: 50%;
            transform: translateY(-50%);
            font-family: icomoon;
        }
    }
    &.opaque {
        background-color: transparent;
        color: #fff;
        border: 1px solid #fff;
        &:hover {
            color: $blue;
            border-color: $blue;
        }
    }
    // font-size: 18px;
}
.text-center {
    text-align: center;
}
.text-left {
    text-align: left;
}
.text-right {
    text-align: right;
}
.flex {
    &--aic {
        align-items: center;
    }
}


section {
    ul:not([class]) {
        margin-bottom: 20px;
        margin-left: 15px;
        display: inline-flex;
        flex-direction: column;
        li {
            list-style-type: disc;
        }
    }
    ol:not([class]) {
        margin-bottom: 20px;
        margin-left: 15px;
        display: inline-flex;
        flex-direction: column;
    }
    .text-block.no-dot {
        ul {
            li {
                &:before {
                    content: none;
                }
            }
        }
    }
}
a:not([class]) {
    color: $red;
    &:hover {
        color: $green;
    }
}
.bold {
    font-weight: 700;
}
