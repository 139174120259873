.aside {
    font-family: $poppins;
    &.col {
        flex: 0 0 29%;
        margin-left: 3%
    }
    &--title {
        margin-bottom: 1em;
    }
    &--container {
        padding-bottom: 1rem;
        margin-bottom: 2rem;
        border-bottom: 1px solid #DEDEDE;
    }
    &--nav {
        @include fluid-font(25px);
        li {
            margin-bottom: 1em;
        }
        a {
            color: #222;
            @include fluid-font(17px);
            font-weight: 500;
            &:hover, &.active {
                color: $red;
            }
        }
    }
}
.news {
    // padding: 0;
    h1, h2, h3, h4, h5, h6 {
        text-transform: uppercase;
    }
    .aside {
        &--title {
            @include fluid-font(25px);
            font-weight: 700;
            color: $red;
        }
        .rp {
            li {
                margin-bottom: 1rem;
            }
            a {
                display: flex;
                .img-container {
                    flex: 0 0 61px;
                    margin-right: 5%;
                    position: relative;
                    img {
                        @include bup(md){
                            width: 61px;
                            height: 100%;
                            position: absolute;
                            object-fit: cover;
                        }
                    }
                    iframe {
                        width: 61px;
                        height: 61px;
                        position: absolute;
                    }
                }
                &:hover {
                    .rp--title {
                        color: $red;
                    }
                }
            }
            &--info {
                font-size: 15px;
            }
            &--title {
                font-weight: 500;
                color: #222;
                margin-bottom: .5rem;
                @include transition;
            }
            &--date {
                font-weight: 400;
                color: #777;
            }
        }
    }
    @include bup(md){
        .aside {
            padding-left: 15px;
        }
    }
}
article {
    margin-bottom: 52px;
    position: relative;
    @include bdown(md) {
        margin-bottom: 25px;
    }
    .article-header {
        @include transition;
        @include fluid-font(25px);
        font-weight: 700;
        text-align: left;
        margin-bottom: 1em;
        a {
            color: $red;
            &:hover {
                color: $blue;
            }
        }
    }
    .article-inner {
        .date {
            font-size: 13px;
            color: #777;
            margin-bottom: .5rem;
            i {
                color: $yellow;
                margin-right: .5em;
            }
        }
        .excerpt, .article-text {
            @include fluid-font(15px);
            margin-top: 1.5em;
            // max-width: 83%;
            line-height: 30px;
            @include bdown(md) {
                max-width: none;
                margin-top: 2em;
            }
        }
        .btn-container {
            .read-more {
                font-size: 15px;
                color: $blue;
                &:hover {
                    color: $blue;
                }
            }
        }
        .featured-media-container {
            &.video {
                position: relative;
                padding-bottom: 56.25%;
                height: 0;
                iframe {
                    position: absolute;
                    left: 0;
                    top: 0;
                    height: 100%;
                    width: 100%;
                }
            }
        }
        .blog-media {
            margin-bottom: 1rem;
        }
        .article-bottom {
            display: flex;
            justify-content: space-between;
            margin-top: 1rem;
            .read-more {
                @include fluid-font(15px);
                font-weight: 600;
                color: $red;
                i {
                    margin-left: .5em;
                }
            }
            ul {
                @extend %share-style1;
                display: inline-flex;
                li {
                    position: relative;
                    display: inline-flex;
                    align-items: center;
                    &:after {
                        content: "|";
                        color: $red;
                        display: inline-block;
                        font-size: .5rem;
                    }
                    &:last-of-type {
                        &:after {
                            content: none;
                        }
                    }
                }
                a {
                    color: $red;
                    &:hover {
                        color: $blue;
                    }
                }
            }
        }

    }
    // &.article-item {
    //     .article-inner {
    //         padding: 1.9rem 3.3rem;
    //         background-color: #FAF6EC;
    //         box-shadow: 0 16px 27px rgba($color: #000, $alpha: .2);
    //         @include bdown(md) {
    //             padding: 25px 3% 17px;
    //         }
    //     }
    // }
    &.article-single {
        border: none;
        margin-bottom: 0;
        & > h2 {
            max-width: 1000px;
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        }
        .article-inner {
            position: relative;
            max-width: 1000px;
            .article-text {
                font-family: $montserrat;
                @include fluid-font(19px);
            }
            
            @include block-center;
            h2.single-article-header {
                @include fluid-font(30px);
                font-weight: 700;
                color: $red;
            }
            .article-text {
                max-width: none;
                color: #777;
                @include font-count(13, 15);
                p {
                    @include font-count(13, 15);
                    padding-bottom: 1.5em;
                    &:first-of-type {
                        color: $red;
                        font-weight: 700;
                        @include fluid-font(20px);
                    }
                }
            }
            .soc-share-container {
                padding-top: 1rem;
                padding-bottom: 1rem;
                border-top: 1px solid rgba(#C8C8C8, $alpha: .6);
                border-bottom: 1px solid rgba(#C8C8C8, $alpha: .6);
                margin-bottom: 2rem;
                @extend %share-style1;
                justify-content: space-between;
                .ssc-title {
                    color: $red;
                    font-weight: 700;
                    @include fluid-font(17px);
                }
                .soc-share {
                    a {
                        color: $red;
                        &:hover {
                            color: $blue;
                        }
                    }
                }
                @include bdown(md) {
                    display: block;
                    .ssc-title {
                        margin-bottom: 1rem;
                        text-align: center;
                    }
                }
            }
            .article-navigation {
                display: flex;
                justify-content: space-between;
                a {
                    flex: 0 1 45%;
                    position: relative;
                    font-weight: 500;
                    div:nth-of-type(1){
                        @include fluid-font(15px);
                        font-weight: 700;
                        color: $red;
                        margin-bottom: 1rem;
                    }
                    div:nth-of-type(2){
                        @include fluid-font(19px);
                    }
                    &:hover {
                        color: $blue;
                    }
                    &:after {
                        content: '\e902';
                        position: absolute;
                        top: 50%;
                        transform: translateY(-50%);
                        font-family: icomoon;
                        font-size: 1.5rem;
                        color: #BBBBBB;
                    }
                    &:first-child {
                        padding-left: 2rem;
                        &:after {
                            left: 0;
                        }
                    }
                    &:last-child {
                        padding-right: 2rem;
                        &:after {
                            transform: translateY(-50%) rotate(180deg);
                            right: 0;
                        }
                        @include bup(md){
                            text-align: right;
                        }
                    }
                }
                @include bdown(md){
                    flex-direction: column;
                    a {
                        margin-bottom: 2rem;
                        div:first-of-type{
                            margin-bottom: .5rem;
                        }
                    }
                }
            }
        }
        .backlink-container {
            margin-bottom: 1rem;
            a {
                @include fluid-font(17px);
                font-weight: 400;
                color: #2C2C2C;
                display: inline-flex;
                align-items: center;
                &:before {
                    color: $red;
                }
                &:hover {
                    color: $blue;
                }
            }
        }
        @include bdown(md){
            padding-left: 5%;
            padding-right: 5%;
        }
    }
}
.pagination-container {
    text-align: center;
}
.pagination {
    display: inline-flex;
    // align-items: center;
    padding-bottom: 20px;
    @include bdown(md) {
        display: flex;
        justify-content: space-between; 
    }
    .pagination-list {
        flex-grow: 1;
        display: flex;
        justify-content: space-around;
        li {
            display: inline-block;
            margin-right: 7px;
            a {
                color: #777777;
                padding: 20px;
                text-align: center;
                padding: 8px 16px;
                font-weight: 300;
                border: 1px solid #C8C8C8;
                border-radius: 3px;
                @include font-count(12,24);
                &:hover, &.active-pag {
                    background-color: $green;
                    border-color: $green;
                    color: #fff;
                }
            }
        }
        @include bdown(md) {
            li {
                margin: 0 1px;
                a {
                    min-width: 0;
                    padding: 9px 15px;
                }
            }
        }
        @include bdown(xs) {
            li a {
                padding: 9px;
            }
        }
    }
    .pag-arrow {
        border-radius: 5px;
        color: #777777;
        @include font-count(12,24);
        padding: 4px 8px;
        display: inline-flex;
        align-items: center;
        border: 1px solid #C8C8C8;
        flex-shrink: 0;
        display: flex;
        align-content: center;
        // img {
        //     width: 1em;
        //     height: 1em;
        // }
        &:hover {
            color: #fff;
            background-color: $green;
        }
        &:first-of-type {
            margin-right: 20px;
        }
        &:last-of-type {
            margin-left: 20px;
            &:before{
                transform: rotate(180deg);
            }
        }
        @include bdown(md) {
            &:first-of-type {
                margin-right: 7px;
            }
            &:last-of-type {
                margin-left: 7px;
            }
        }
        @include bdown(xs) {
            min-width: 30px;
            padding: 5px;
            &:first-of-type {
                margin-right: 5px;
            }
            &:last-of-type {
                margin-left: 5px;
            }
        }
    }
    &.single-pagination {
        display: flex;
        .pagination-list {
            justify-content: space-between;
        }
    }
}