//Imports
@import "components/components.scss";
@import "base/base.scss";
@import "parts/parts.scss";
.hidden-mobile {
    @include bdown(md) {
        display: none !important;
    }
}
.hidden-desktop {
    @include bup(md) {
        display: none !important;
    }
}

/**********************
******  LAYOUYT  ******
**********************/

section {
    padding: 42px 0;
    @include bdown(md) {
        padding: 30px 0;
    }
    &.no-pad {
        padding: 0 !important;
    }
    &.no-pad-top {
        padding-top: 0 !important;
    }
    &.no-pad-bot {
        padding-bottom: 0 !important;
    }
    &.narrow {
        .container {
            max-width: 1130px;
        }
    }
    &.wide {
        .container {
            max-width: 1630px;
        }
    }
    &.mid {
        .container {
            max-width: 1330px;
        }
    }
    &.clean {
        background-image: none !important;
    }
}
.container {
    padding: 0 15px;
    margin-left: auto;
    margin-right: auto;
    max-width: 1330px;
    &.fw {
        max-width: none;
        padding: 0;
        .row {
            margin-left: auto;
            margin-right: auto;
        }
    }
    &.narrow {
        max-width: 1130px;
    }
    &.slim {
        max-width: 886px;
    }
    &.wide {
        max-width: 1630px;
    }
    &.semiwide {
        max-width: 1430px;
    }
    &.wide {
        max-width: 1560px;
    }
    @include bdown(md) {
        padding: 0 5%;
        &.fw {
            .box {
                padding: 0 5%;
            }
        }
    }
}
.row {
    display: flex;
    margin-left: -15px;
    margin-right: -15px;
    &.vcenter {
        align-items: center;
    }
    & + .row {
        padding-top: 0;
    }
    &.no-pad {
        padding: 0;
    }
    &.no-mar {
        margin: 0;
    }
    &.block {
        display: block;
        margin-left: 0;
        margin-right: 0;
    }
    @include bdown(md) {
        padding-top: 10px;
        padding-bottom: 10px;
        display: block;
        &.eq-h {
            height: auto !important;
        }
    }
    &.no-block {
        @include bdown(md) {
            display: flex;
        }
    }
}
.col {
    padding: 0 15px;
    flex: 1 0 0;
    box-sizing: border-box;
    @for $i from 1 through 100 {
        $flex-basis: $i;
        &.basis-#{$i} { 
            flex-basis: percentage($flex-basis)/100;
            flex-grow: 0;
        }
    }
    // &[class^=" basis"]{
    //     flex-grow: 0;
    // }
    // .zoomed {
    //     &[class*=" aspect-"] {
    //         margin-bottom: $spacer * 4.0;
    //     }
    // }
    @include bdown(md) {
        margin-bottom: 20px;
        &:last-of-type {
            margin-bottom: 0;
        }
    }
}
.include-pad {
    padding: 42px 0;
    @include bdown(md) {
        padding: 30px 0;
    }
}
.box {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
}

.clearfix {
    &:after{
        display: table;
        content: "";
        clear: both;
    }

}


